export default [
  {
    item: 'Inicio',
    link: '/home',
  },
  {
    item: 'Guías',
    link: '/guides',
  },
  {
    item: 'Guías con excel',
    link: '/guides/many',
  },
  {
    item: 'Asignación',
    link: '/assignments',
  },
  {
    item: 'Asignación con excel',
    link: '/assignments/many',
  },
  {
    item: 'Crear base de datos',
    link: '/databases',
  },
  {
    item: 'Empresas',
    link: '/companies',
  },
  {
    item: 'Cargar guías',
    link: '/upload',
  },
  {
    item: 'Mensajeros',
    link: '/deliveries',
  },
  {
    item: 'Usuarios',
    link: '/users',
  },
  {
    item: 'Zonas',
    link: '/zones',
  },
];
