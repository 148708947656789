import React, { forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { msg } from '../../../config/validationFormSettings';
import { excelToJson } from '../../../tools';
import TextErrorValidate from '../TextErrorValidate/TextErrorValidate';

const FILE_SIZE = 3000000;
const SUPPORTED_FORMATS = [
  '.csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
];

const validateFile = file => {
  let errorMessage = '';
  if (!file) {
    errorMessage = msg.REQUIRED_ERROR;
  } else if (file.size > FILE_SIZE) {
    errorMessage = msg.EXCEL_FILE_SIZE_ERROR;
  } else if (!SUPPORTED_FORMATS.includes(file.type)) {
    errorMessage = msg.UNSUPPORTED_FORMATS_ERROR;
  }
  return errorMessage;
};

const InputFileExcel = forwardRef(({ label, name }, ref) => {
  const [fileErrors, setFileErrors] = useState('');
  const [fileState, setFileState] = useState('');

  const handleOnChange = async e => {
    const fileValue = e.target.files[0];
    setFileErrors(validateFile(fileValue));

    if (!fileErrors) {
      const guides = await excelToJson(fileValue, { barcode: 'GUIA' });
      setFileState(guides);
    }
  };

  const getGuidesState = () => [fileErrors, fileState];

  useImperativeHandle(ref, () => {
    return {
      getGuidesState,
    };
  });

  return (
    <div className="input-control">
      <label htmlFor={name}>{label}</label>
      <input
        accept={SUPPORTED_FORMATS.join(', ')}
        className="input-xsmall"
        id={name}
        name={name}
        onChange={handleOnChange}
        type="file"
      />
      {fileErrors && <TextErrorValidate>{fileErrors}</TextErrorValidate>}
    </div>
  );
});

InputFileExcel.propTypes = {
  label : PropTypes.string.isRequired,
  name  : PropTypes.string.isRequired,
};

export default InputFileExcel;
