import React from 'react';
import { useParams } from 'react-router-dom';
import { TableMain } from '../../components/commons';
import FormDeliveries from './form';

const Deliveries = () => {
  const { action } = useParams();

  const tableHead = [
    {
      name: 'Mensajero',
    },
  ];

  const tableRows = ['name'];

  return (
    <>
      <h6>MENSAJEROS</h6>
      {action ? <FormDeliveries /> : <TableMain tableRows={tableRows} tableHead={tableHead} />}
    </>
  );
};

export default Deliveries;
