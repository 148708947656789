import React from 'react';
import { localInitialValues, formSchema } from './formSchema';
import { FormButtons, RowField } from '../../../components/containers';
import { FormMain, InputFormik } from '../../../components/commons';

const FormUsers = () => {
  return (
    <FormMain localInitialValues={localInitialValues} validationSchema={formSchema}>
      <>
        <RowField col={6}>
          <InputFormik label="Documento" name="identificationDocument" type="number" />
        </RowField>
        <RowField col={6}>
          <InputFormik label="Primer nombre" name="firstName" type="text" />
        </RowField>
        <RowField col={6}>
          <InputFormik label="Primer apellido" name="lastName" type="text" />
        </RowField>
        <RowField col={6}>
          <InputFormik label="Correo" name="email" type="email" />
        </RowField>
        <RowField col={12}>
          <FormButtons />
        </RowField>
      </>
    </FormMain>
  );
};

export default FormUsers;
