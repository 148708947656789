/* eslint-disable no-await-in-loop */
import { BarcodeFormat, BrowserMultiFormatReader, DecodeHintType } from '@zxing/library';
import cutImage from '../cutImage/cutImage';

const barcodeReader = async fileArray => {
  const hints = new Map();
  hints.set(DecodeHintType.TRY_HARDER, true);
  hints.set(DecodeHintType.POSSIBLE_FORMATS, [BarcodeFormat.CODE_39, BarcodeFormat.CODE_128]);
  const codeReader = new BrowserMultiFormatReader(hints);
  const barcode = [];

  for (const file of fileArray) {
    let result = {};
    try {
      const cutImageUrl = await cutImage(file);
      const resultImage = await codeReader.decodeFromImageUrl(cutImageUrl);
      result = {
        barcode: resultImage.text,
        error: false,
      };
    } catch (error) {
      result = {
        barcode: 'N/A',
        error: true,
      };
    }

    barcode.push({
      ...result,
      image: file,
      name: file.name,
      type: file.type.split('/')[1],
    });
  }

  return barcode;
};

export default barcodeReader;
