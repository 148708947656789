import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import store from '../redux/store';
import { AuthProvider } from '../components/context/AuthContext/AuthContext';
import PrivateLayout from '../components/containers/privateLayout/PrivateLayout';
import PublicLayout from '../components/containers/publicLayout/PublicLayout';

const App = () => {
  return (
    <AuthProvider>
      <Provider store={store}>
        <Router basename={process.env.PUBLIC_URL}>
          <Switch>
            <Route path="/login" component={PublicLayout} />
            <Route path="/" component={PrivateLayout} />
          </Switch>
        </Router>
      </Provider>
    </AuthProvider>
  );
};

export default App;
