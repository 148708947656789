import * as Yup from 'yup';
import { msg, rgx } from '../../../config/validationFormSettings';

export const localInitialValues = {
  name: '',
  price: '',
};

export const formSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, msg.STRING_MIN_ERROR)
    .max(30, msg.STRING_MAX_ERROR)
    .matches(rgx.STRING_REGEX, msg.STRING_TYPE_ERROR)
    .required(msg.REQUIRED_ERROR),
  price: Yup.number().min(0, msg.NUMBER_MIN_ERROR).required(msg.REQUIRED_ERROR).typeError(msg.NUMBER_TYPE_ERROR),
});
