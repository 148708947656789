import * as XLSX from 'xlsx';

export const normalizeData = (data, isJson, objectSchema) => {
  const schemaKeys = isJson ? Object.keys(objectSchema) : Object.values(objectSchema);
  const schemaValues = isJson ? Object.values(objectSchema) : Object.keys(objectSchema);

  // Receive an array of objects
  const normalize = data.map(e => {
    // and exchange the keys of each object for the keys of the schema
    const mapEntries = Object.entries(e).map(([key, value]) => {
      const index = schemaKeys.indexOf(key);
      return [schemaValues[index], value];
    });

    return Object.fromEntries(mapEntries);
  });

  return normalize;
};

export const jsonToExcel = (jsonData, objectSchema, name) => {
  const data = normalizeData(jsonData, true, objectSchema);
  // new worksheet from data
  const ws = XLSX.utils.json_to_sheet(data, { header: Object.values(objectSchema) });
  // new workbook
  const wb = XLSX.utils.book_new();
  // add worksheet to workbook
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  XLSX.writeFile(wb, `${name}.xlsx`);
};

export const excelToJson = async (file, objectSchema) => {
  const fileReader = new FileReader();
  fileReader.readAsBinaryString(file);

  return new Promise(resolve => {
    fileReader.onerror = () => {
      fileReader.abort();
      resolve([]);
    };

    fileReader.onload = event => {
      const { result } = event.target;
      const excel = XLSX.read(result, { type: 'binary' });
      const sheetName = excel.SheetNames;
      const data = XLSX.utils.sheet_to_json(excel.Sheets[sheetName[0]]);
      resolve(normalizeData(data, false, objectSchema));
    };
  });
};
