import React from 'react';

const Home = () => {
  return (
    <>
      <h1>Bienvenido a Mail Queen</h1>
      <p>
        Mail Queen es un software de gestión de logística. Se encarga de llevar un control
        organizado de la vida útil de los paquetes y correspondencias desde su distribución hasta
        que llegan al cliente final o son devueltos.
      </p>
    </>
  );
};

export default Home;
