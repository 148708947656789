import React from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import uniqid from 'uniqid';

import { useReduxDispatch } from '../../../hooks';
import HeroInfo from '../HeroInfo/HeroInfo';
import Pagination from '../Pagination/Pagination';
import TableActions from '../TableActions/TableActions';

const TableMain = ({ tableHead, tableRows }) => {
  const { filteredModuleData } = useReduxDispatch();
  const { module } = useParams();

  return (
    <div className="card overflow-visible glass">
      <table className="u-full-width table">
        <thead>
          <tr>
            {tableHead.map(e => (
              <th key={uniqid()}>{e.name}</th>
            ))}
            <th>
              <Link to={`/${module}/add`} className="btn-transparent btn-dropdown btn-xsmall m-0">
                <i className="fas fa-plus" />
              </Link>
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredModuleData.length ? (
            filteredModuleData.map(row => {
              return (
                <tr key={uniqid()}>
                  {tableRows.map(cell => {
                    return <td key={uniqid()}>{row[cell]}</td>;
                  })}
                  <td>
                    <TableActions id={row.id} />
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={tableHead.length + 1}>
                <HeroInfo
                  title="Tabla vacía"
                  subtitle="No se ha encontrado ningún dato"
                  icon="folder-open"
                />
              </td>
            </tr>
          )}
        </tbody>
        <tfoot>
          <tr>
            <th colSpan={tableHead.length + 1}>
              <Pagination />
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

TableMain.propTypes = {
  tableHead: PropTypes.instanceOf(Array).isRequired,
  tableRows: PropTypes.instanceOf(Array).isRequired,
};

export default TableMain;
