import React from 'react';
import { useParams } from 'react-router-dom';
import { TableMain } from '../../components/commons';
import FormCompanies from './form';

const Companies = () => {
  const { action } = useParams();
  const tableHead = [{ name: 'NIT' }, { name: 'Empresa' }];
  const tableRows = ['nit', 'name'];

  return (
    <>
      <h6>EMPRESAS</h6>
      {action ? <FormCompanies /> : <TableMain tableRows={tableRows} tableHead={tableHead} />}
    </>
  );
};

export default Companies;
