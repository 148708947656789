import React, { forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

const ImageModal = forwardRef(({ index, onSubmit, src }, ref) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => {
    URL.revokeObjectURL(src);
    setOpen(false);
  };
  const handleChange = e => setInputValue(e.target.value);
  const handleSubmit = e => {
    e.preventDefault();
    onSubmit(index, inputValue);
    setInputValue('');
    onCloseModal();
  };

  useImperativeHandle(ref, () => {
    return {
      onOpenModal,
    };
  });

  return (
    <Modal open={open} onClose={onCloseModal} center>
      <img src={src} alt={src} />
      <div className="u-flex u-justify-center u-items-center bg-orange-100">
        <input
          type="number"
          onChange={handleChange}
          onKeyDown={e => e.key === 'Enter' && handleSubmit(e)}
          value={inputValue}
        />
        <input className="m-0" onClick={handleSubmit} type="button" value="✔️" />
      </div>
    </Modal>
  );
});

ImageModal.propTypes = {
  index    : PropTypes.number.isRequired,
  onSubmit : PropTypes.func.isRequired,
  src      : PropTypes.string.isRequired,
};

export default ImageModal;
