import React from 'react';
import { useParams } from 'react-router-dom';
import { TableMain } from '../../components/commons';
import FormZones from './form';

const Zones = () => {
  const { action } = useParams();

  const tableHead = [
    {
      name: 'Zona',
    },
    {
      name: 'Valor',
    },
  ];

  const tableRows = ['name', 'price'];

  return (
    <>
      <h6>ZONAS</h6>
      {action ? <FormZones /> : <TableMain tableRows={tableRows} tableHead={tableHead} />}
    </>
  );
};

export default Zones;
