import React from 'react';
import { useParams } from 'react-router-dom';

const SubmitButton = () => {
  const { action } = useParams();
  const valueButton = action === 'edit' ? 'MODIFICAR' : 'GUARDAR';
  return (
    <input className="btn-animated btn-info" type="submit" value={valueButton} />
  );
};

export default SubmitButton;
