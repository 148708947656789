import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useField, ErrorMessage } from 'formik';
import { getDataService } from '../../../services/http';
import TextErrorValidate from '../TextErrorValidate/TextErrorValidate';

const SelectFormik = ({ label, apiData, ...props }) => {
  const [apiModule, optionName] = apiData;
  const { id } = useParams();
  const [field] = useField(props);
  const [selectState, setSelectState] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const { results } = await getDataService(apiModule);
      setSelectState(results);
    }
    fetchData();
  }, [apiModule, setSelectState]);

  return (
    <div className="input-control">
      <label htmlFor={field.name}>{label}</label>
      <select className="select" id={field.name} placeholder={label} {...field} {...props}>
        {!id && <option value="">{label}</option>}
        {selectState.length &&
          selectState.map(option => (
            <option key={option.id} value={option.id}>
              {option[optionName]}
            </option>
          ))}
      </select>
      <ErrorMessage component={TextErrorValidate} name={field.name} />
    </div>
  );
};

SelectFormik.propTypes = {
  apiData : PropTypes.instanceOf(Array).isRequired,
  label   : PropTypes.string.isRequired,
};

export default SelectFormik;
