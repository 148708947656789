import { getDataService } from '../../services/http';
import {
  CLEAN_STATE,
  DELETE,
  FILTER,
  GENERAL_REDUCER,
  GET_BY_ID,
  INIT,
  PAGINATE,
  SORT,
} from './generalActions';

const initialState = {
  filteredModuleData : [],
  moduleData         : [],
  moduleDataById     : [],
  paginateInfo       : null,
};

// Reducer
export default (state = initialState, action) => {
  const { payload, type } = action;
  return GENERAL_REDUCER[type] ? GENERAL_REDUCER[action.type](state, payload) : state;
};

// Action Creators
export const cleanState = () => {
  return {
    type    : CLEAN_STATE,
    payload : [],
  };
};

export const deleteModuleData = (id) => {
  return {
    type    : DELETE,
    payload : { id },
  };
};

export const moduleInitData = module => {
  return async dispatch => {
    const { results, info } = await getDataService(module);

    dispatch({
      type    : INIT,
      payload : { results, info },
    });
  };
};

export const filterModuleData = (key, value) => {
  return {
    type    : FILTER,
    payload : { key, value },
  };
};

export const getModuleDataById = id => {
  return {
    type    : GET_BY_ID,
    payload : { id },
  };
};

export const paginate = path => {
  return async dispatch => {
    const { results, info } = await getDataService(path);

    dispatch({
      type    : PAGINATE,
      payload : { results, info },
    });
  };
};

export const sortModuleData = (key, value) => {
  return {
    type    : SORT,
    payload : { key, value },
  };
};
