import React from 'react';
import { useParams } from 'react-router-dom';
import FormAssignments from './form';
import FormManyAssignments from './form/FormManyAssignments';
import { AssignmentCard, SearchPanel } from '../../components/commons';

const Assignments = () => {
  const { action } = useParams();

  return (
    <>
      <h6>ASIGNACIÓN DE GUÍAS</h6>
      {(action === 'many' && <FormManyAssignments />) ||
        (action && <FormAssignments isDisabled={action === 'edit'} />) || (
        <>
          <SearchPanel filterBy="databases" />
          <AssignmentCard />
        </>
      )}
    </>
  );
};

export default Assignments;
