import React from 'react';
import PropTypes from 'prop-types';

const TextErrorValidate = ({ children }) => <span className="required info">{children}</span>;

TextErrorValidate.propTypes = {
  children: PropTypes.string.isRequired,
};

export default TextErrorValidate;
