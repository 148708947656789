import React from 'react';
import PropTypes from 'prop-types';

const RowField = ({ children, col }) => {
  return <div className={`col-${col} pl-0`}>{children}</div>;
};

RowField.propTypes = {
  children : PropTypes.element.isRequired,
  col      : PropTypes.number.isRequired,
};

export default RowField;
