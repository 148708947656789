import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useField, ErrorMessage } from 'formik';
import TextErrorValidate from '../TextErrorValidate/TextErrorValidate';

const SelectFormikLocalData = ({ label, localData, ...props }) => {
  const { id } = useParams();
  const [field] = useField(props);

  return (
    <div className="input-control">
      <label htmlFor={field.name}>{label}</label>
      <select className="select" id={field.name} placeholder={label} {...field} {...props}>
        {!id && <option value="">{label}</option>}
        {localData.map(option => (
          <option key={option.id} value={option.id}>
            {option.name || option.displayName}
          </option>
        ))}
      </select>
      <ErrorMessage component={TextErrorValidate} name={field.name} />
    </div>
  );
};

SelectFormikLocalData.propTypes = {
  label     : PropTypes.string.isRequired,
  localData : PropTypes.instanceOf(Array).isRequired,
};

export default SelectFormikLocalData;
