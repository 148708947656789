const canvas = document.createElement('canvas');
const context = canvas.getContext('2d', { alpha: false });

const cutImage = fileObject => {
  return new Promise(resolve => {
    const newImage = new Image();
    newImage.src = URL.createObjectURL(fileObject);

    newImage.onload = () => {
      const { width, height } = newImage;
      canvas.width = width;
      canvas.height = height / 2;
      context.drawImage(newImage, 0, 0);

      URL.revokeObjectURL(newImage.src);
      resolve(canvas.toDataURL('image/jpeg', 0.8));
    };
  });
};

export default cutImage;
