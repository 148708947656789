import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useUser } from '../../hooks';
import { loginWithEmailAndPassword } from '../../services/firebase';
import { FormMain, InputFormik } from '../../components/commons';
import { localInitialValues, formSchema } from './form/FormDataSchema';
import logo from '../../assets/img/mail-queen-logo.png';
import './login.css';

const FormLogin = () => {
  const history = useHistory();
  const { isLogged, isLoading, saveUser } = useUser();

  useEffect(() => {
    if (isLogged) {
      history.replace('/home');
    }
  }, [isLogged, history]);

  const handleLogin = async data => {
    const user = await loginWithEmailAndPassword(data);
    saveUser(user);
    history.replace('/home');
  };

  return (
    <div className="login-content mt-5 pt-5">
      <FormMain callback={handleLogin} localInitialValues={localInitialValues} validationSchema={formSchema}>
        <div className="login-content">
          <img className="login-img" src={logo} alt="Mail Queen Logo" />
          <InputFormik label="Correo Electrónico" name="email" type="email" />
          <InputFormik label="Contraseña" name="password" type="password" />
          <input className="btn-info" type="submit" value="Iniciar sesión" disabled={isLoading} />
        </div>
      </FormMain>
    </div>
  );
};

export default FormLogin;
