import swal from 'sweetalert';

export const alertMessage = (type, msg = '') => {
  const alertConfig = {
    error: {
      title: '¡Datos inválidos!',
      subtitle: msg || 'No se ha podido realizar la operación.',
    },
    info: {
      title: 'Operación cancelada',
      subtitle: msg || 'La operación ha sido cancelada.',
    },
    success: {
      title: 'Operación exitosa!',
      subtitle: msg || 'Operación realizada con éxito.',
    },
  };

  return swal(alertConfig[type].title, alertConfig[type].subtitle, type, {
    buttons: false,
  });
};

export const alertConfirm = () => {
  return new Promise(resolve => {
    swal('¿Está seguro de eliminar este item?', {
      buttons: ['Cancelar', 'Eliminar'],
      dangerMode: true,
    }).then(resolve);
  });
};
