// Actions
export const CLEAN_STATE = 'mailQueen/general/clean';
export const DELETE = 'mailQueen/general/delete';
export const INIT = 'mailQueen/general/init';
export const FILTER = 'mailQueen/general/filter';
export const GET_BY_ID = 'mailQueen/general/getById';
export const PAGINATE = 'mailQueen/general/paginate';
export const SORT = 'mailQueen/general/sort';

// Reducer actions
export const GENERAL_REDUCER = {};

GENERAL_REDUCER[CLEAN_STATE] = (state, payload) => {
  return {
    ...state,
    filteredModuleData : payload,
    moduleData         : payload,
    paginateInfo       : null,
  };
};

GENERAL_REDUCER[DELETE] = (state, payload) => {
  const { id } = payload;
  return {
    ...state,
    filteredModuleData : state.filteredModuleData.filter(e => e.id !== id),
    moduleData         : state.moduleData.filter(e => e.id !== id),
  };
};

GENERAL_REDUCER[INIT] = (state, payload) => {
  const { results, info } = payload;

  return {
    ...state,
    filteredModuleData : results,
    moduleData         : results,
    paginateInfo       : info,
  };
};

GENERAL_REDUCER[FILTER] = (state, payload) => {
  const { key, value } = payload;
  return {
    ...state,
    filteredModuleData: state.moduleData.filter(e => e[key] === value),
  };
};

GENERAL_REDUCER[GET_BY_ID] = (state, payload) => {
  const { id } = payload;
  return {
    ...state,
    moduleDataById: state.moduleData.filter(e => e.id === id),
  };
};

GENERAL_REDUCER[PAGINATE] = (state, payload) => {
  const { info, results } = payload;
  return {
    ...state,
    filteredModuleData : results,
    paginateInfo       : info,
  };
};

GENERAL_REDUCER[SORT] = (state, payload) => {
  const { key, value } = payload;
  const sortAssignmentData = (_, e) => (e[key] === value ? 1 : 0);
  return {
    ...state,
    filteredModuleData: state.filteredModuleData.sort(sortAssignmentData),
  };
};
