import React from 'react';
import { localInitialValues, formSchema } from './formSchema';
import { FormButtons, RowField } from '../../../components/containers';
import { FormMain, InputFormik, SelectFormik, TextAreaFormik } from '../../../components/commons';

const FormGuides = () => {
  return (
    <FormMain localInitialValues={localInitialValues} validationSchema={formSchema}>
      <>
        <RowField col={6}>
          <SelectFormik label="Base de datos" name="database" apiData={['databases', 'name']} />
        </RowField>
        <RowField col={6}>
          <InputFormik label="N° Guía" name="barcode" type="number" />
        </RowField>
        <RowField col={6}>
          <InputFormik label="Orden de servicio" name="orderService" type="number" />
        </RowField>
        <RowField col={6}>
          <InputFormik label="Servicio" name="service" type="text" />
        </RowField>
        <RowField col={6}>
          <InputFormik label="Destinatario" name="addressee" type="text" />
        </RowField>
        <RowField col={6}>
          <InputFormik label="Departamento" name="department" type="text" />
        </RowField>
        <RowField col={6}>
          <InputFormik label="Ciudad" name="city" type="text" />
        </RowField>
        <RowField col={6}>
          <InputFormik label="Dirección destinatario" name="receiverAddress" type="text" />
        </RowField>
        <RowField col={12}>
          <TextAreaFormik label="Descripción" name="description" />
        </RowField>
        <RowField col={12}>
          <FormButtons />
        </RowField>
      </>
    </FormMain>
  );
};

export default FormGuides;
