import React from 'react';
import PropTypes from 'prop-types';
import { useField, ErrorMessage } from 'formik';
import TextErrorValidate from '../TextErrorValidate/TextErrorValidate';

const CheckboxFormik = ({ label, ...props }) => {
  const [field] = useField(props);

  return (
    <div className="input-control">
      <label htmlFor={field.name}>{label}</label>
      <div className="form-ext-control">
        <label className="form-ext-toggle__label" htmlFor={field.name}>
          <div className="form-ext-toggle form-ext-toggle--success">
            <input
              checked={field.value}
              className="form-ext-input"
              id={field.name}
              type="checkbox"
              {...field}
              {...props}
            />
            <div className="form-ext-toggle__toggler">
              <i className="fa" data-check-icon="" data-uncheck-icon="" aria-hidden="true" />
            </div>
          </div>
        </label>
        <ErrorMessage component={TextErrorValidate} name={field.name} />
      </div>
    </div>
  );
};

CheckboxFormik.propTypes = {
  label: PropTypes.string.isRequired,
};

export default CheckboxFormik;
