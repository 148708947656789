import { useCallback, useContext, useState } from 'react';
import { AuthContext } from '../../components/context/AuthContext/AuthContext';
import fb from '../../config/firebaseConfig';

export default function useUser() {
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const saveUser = useCallback(user => {
    setIsLoading(true);
    setCurrentUser(user);
    sessionStorage.setItem('currentUser', JSON.stringify(user));
    setIsLoading(false);
  }, [setCurrentUser]);

  const logout = useCallback(() => {
    fb.auth().signOut();
    sessionStorage.removeItem('currentUser');
    setCurrentUser(null);
  }, [setCurrentUser]);

  return {
    isLogged: Boolean(currentUser),
    isLoading,
    saveUser,
    logout,
  };
}
