import React from 'react';
import { Link } from 'react-router-dom';
import uniqid from 'uniqid';
import menuItems from '../../../config/menuItems';

const HorizontalMenu = () => {
  return (
    <ul className="menu">
      <li className="divider" data-label="CORREO" />
      <li className="menu-item">
        <Link to="/home">Inicio</Link>
      </li>
      <li className="menu-item">
        <div className="menu-addon">
          <span className="icon">
            <i className="fa fa-wrapper fa-folder small" aria-hidden="true" />
          </span>
        </div>
        <Link className="bg-teal-100" to="/home">
          Gestión de correos
        </Link>
        <ul className="menu">
          {menuItems.map(({ item, link }) => (
            <li className="menu-item" key={uniqid()}>
              <Link to={link}>{item}</Link>
            </li>
          ))}
        </ul>
      </li>
      <li className="menu-item selected">
        <Link to="/login">Cerrar sesión</Link>
      </li>
    </ul>
  );
};

export default HorizontalMenu;
