import React from 'react';
import PropTypes from 'prop-types';
import { useFormik, FormikProvider } from 'formik';
import { useFormInitialValue } from '../../../hooks';
import Row from '../../containers/row/Row';

const FormMain = ({ callback, children, localInitialValues, validationSchema }) => {
  const { onSubmit, initialValues } = useFormInitialValue(callback, localInitialValues);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div className="card overflow-visible glass p-5">
      <FormikProvider value={formik}>
        <form autoComplete="off" noValidate onSubmit={formik.handleSubmit}>
          <Row>{children}</Row>
        </form>
      </FormikProvider>
    </div>
  );
};

FormMain.propTypes = {
  callback           : PropTypes.func,
  children           : PropTypes.element.isRequired,
  localInitialValues : PropTypes.instanceOf(Object).isRequired,
  validationSchema   : PropTypes.instanceOf(Object).isRequired,
};

FormMain.defaultProps = {
  callback: undefined,
};

export default React.memo(FormMain);
