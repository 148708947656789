import * as Yup from 'yup';
import { msg, rgx } from '../../../config/validationFormSettings';

export const localInitialValues = {
  addressee       : '',
  barcode         : '',
  city            : '',
  database        : '',
  department      : '',
  description     : '',
  orderService    : '',
  receiverAddress : '',
  service         : '',
};

export const excelSchema = {
  addressee       : 'DESTINATARIO',
  barcode         : 'GUIA',
  city            : 'MUNICIPIO',
  department      : 'DEPARTAMENTO',
  description     : 'DESCRIPCION',
  orderService    : 'ORDEN SERVICIO',
  receiverAddress : 'DIRECCION',
  service         : 'TIPO DE SERVICIO',
};

export const formSchema = Yup.object().shape({
  barcode: Yup.number().required(msg.REQUIRED_ERROR).typeError(msg.NUMBER_TYPE_ERROR),
  database: Yup.string().required(msg.REQUIRED_ERROR).typeError(msg.REQUIRED_ERROR),
  description: Yup.string()
    .max(100, msg.STRING_MAX_ERROR)
    .matches(rgx.ALPHANUMERIC_REGEX, msg.STRING_TYPE_ERROR)
    .required(msg.REQUIRED_ERROR),
  addressee: Yup.string()
    .max(60, msg.STRING_MAX_ERROR)
    .matches(rgx.STRING_REGEX, msg.STRING_TYPE_ERROR)
    .required(msg.REQUIRED_ERROR),
  receiverAddress: Yup.string()
    .max(100, msg.STRING_MAX_ERROR)
    .matches(rgx.ALPHANUMERIC_REGEX, msg.STRING_TYPE_ERROR)
    .required(msg.REQUIRED_ERROR),
  city: Yup.string()
    .max(60, msg.STRING_MAX_ERROR)
    .matches(rgx.STRING_REGEX, msg.STRING_TYPE_ERROR)
    .required(msg.REQUIRED_ERROR),
  department: Yup.string()
    .max(60, msg.STRING_MAX_ERROR)
    .matches(rgx.STRING_REGEX, msg.STRING_TYPE_ERROR)
    .required(msg.REQUIRED_ERROR),
  orderService: Yup.number().required(msg.REQUIRED_ERROR).typeError(msg.NUMBER_TYPE_ERROR),
  service: Yup.string().required(msg.REQUIRED_ERROR),
});

export const formSchemaManyGuides = Yup.object().shape({
  dbGuides: Yup.array().of(formSchema).nullable(),
});
