import * as Yup from 'yup';
import { msg } from '../../../config/validationFormSettings';

export const localInitialValues = {
  user: '',
  status: '',
};

export const formSchema = Yup.object().shape({
  user: Yup.string().required(msg.REQUIRED_ERROR).typeError(msg.REQUIRED_ERROR),
  status: Yup.boolean().required(msg.REQUIRED_ERROR).typeError(msg.NUMBER_TYPE_ERROR),
});
