import React, { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ReactPaginate from 'react-paginate';

import { regexRoutes as regex } from '../../../config/validationFormSettings';
import { useReduxDispatch, useQuery } from '../../../hooks';
import './pagination.css';

const Pagination = () => {
  const { module } = useParams();
  const history = useHistory();
  const filterQuery = useQuery('filter');
  const pageQuery = useQuery('page');
  const { dispatchCleanState, dispatchInit, dispatchPaginate, paginateInfo } = useReduxDispatch();

  const currentPath = useCallback(
    (valuePage = undefined) => {
      const page = valuePage || pageQuery || '1';
      const path = filterQuery ? `?filter=${filterQuery}&page=${page}` : `?page=${page}`;

      return `${module}${path}`;
    },
    [filterQuery, module, pageQuery]
  );

  useEffect(() => {
    const regexModule = new RegExp(`${regex.statePaths}`, 'i');

    if (regexModule.test(module) || filterQuery) {
      const path = currentPath();
      dispatchInit(path);
    } else {
      dispatchCleanState();
    }
  }, [currentPath, dispatchCleanState, dispatchInit, filterQuery, module]);

  const handlePageChange = selected => {
    const valuePage = selected + 1;
    const path = currentPath(valuePage);
    history.push(path);
    dispatchPaginate(path);
  };

  return (
    <ReactPaginate
      activeClassName="active"
      containerClassName="paginate"
      marginPagesDisplayed={1}
      nextLabel="Siguiente"
      onPageChange={({ selected }) => handlePageChange(selected)}
      pageCount={paginateInfo ? paginateInfo.pages : 1}
      pageRangeDisplayed={3}
      previousLabel="Anterior"
    />
  );
};

export default Pagination;
