import React from 'react';
import PropTypes from 'prop-types';
import { useField, ErrorMessage } from 'formik';
import TextErrorValidate from '../TextErrorValidate/TextErrorValidate';

const InputFormik = ({ label, ...props }) => {
  const [field] = useField(props);

  return (
    <div className="input-control">
      <label htmlFor={field.name}>{label}</label>
      <input {...field} {...props} id={field.name} placeholder={label} />
      <ErrorMessage component={TextErrorValidate} name={field.name} />
    </div>
  );
};

InputFormik.propTypes = {
  label: PropTypes.string.isRequired,
};

export default InputFormik;
