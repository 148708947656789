import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import CardPreviewGuide from '../CardPreviewGuide/CardPreviewGuide';
import ImageModal from '../ImageModal/ImageModal';
import './PreviewContainer.css';

const PreviewContainer = ({files, setFiles}) => {
  const modalRef = useRef();
  const [srcImage, setSrcImage] = useState({ index: 0, src: '' });

  const modalOnSubmit = (position, barcode) => {
    const currentFiles = [...files];
    const oldFile = currentFiles.splice(position, 1)[0];
    const newFile = { ...oldFile, barcode, error: false };
    currentFiles.splice(position, 0, newFile);
    setFiles(currentFiles);
  };

  const previewImage = (fileObject, index) => {
    setSrcImage({
      ...srcImage,
      index,
      src: URL.createObjectURL(fileObject),
    });
    modalRef.current.onOpenModal();
  };

  return (
    <>
      <ImageModal
        index={srcImage.index}
        onSubmit={modalOnSubmit}
        ref={modalRef}
        src={srcImage.src}
      />
      <section className="barcode-cards">
        {files.map(({ barcode, error, image, name, type }, key) => {
          return (
            <CardPreviewGuide
              error={error}
              handlePreviewImage={() => previewImage(image, key)}
              icon={type}
              key={uniqid()}
              subtitle={name}
              title={barcode}
            />
          );
        })}
      </section>
    </>
  );
};

PreviewContainer.propTypes = {
  files    : PropTypes.instanceOf(Array).isRequired,
  setFiles : PropTypes.func.isRequired,
};

export default PreviewContainer;
