import React from 'react';
import { Switch, useLocation } from 'react-router-dom';

import { regexRoutes as regex } from '../../../config/validationFormSettings';
import Layout from '../layout/Layout';
import { PrivateRoute } from '../../commons';
import * as Pages from '../../../pages';

const PrivateLayout = () => {
  const { pathname } = useLocation();
  const module = pathname.split('/')[1] || 'home';
  const currentPage = module[0].toUpperCase() + module.slice(1).toLowerCase();

  return (
    <Layout>
      <Switch>
        <PrivateRoute
          exact
          path={`/:module(${regex.modules})/:action(${regex.actions})?/:id(${regex.moduleId})?`}
          component={Pages[currentPage]}
        />
        <PrivateRoute
          exact
          path={`/:module(${regex.manyModules})?/:action(${regex.manyActions})?/:id(${regex.moduleId})?`}
          component={Pages[currentPage]}
        />
        <PrivateRoute path="/*" component={Pages.NotFound} />
      </Switch>
    </Layout>
  );
};

export default PrivateLayout;
