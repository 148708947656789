import React from 'react';
import { useParams } from 'react-router-dom';
import { SearchPanel, TableMain } from '../../components/commons';
import FormDatabases from './form';

const Databases = () => {
  const { action } = useParams();

  const tableHead = [
    {
      name: 'Empresa',
    },
    {
      name: 'Base de datos',
    },
  ];

  const tableRows = ['displayCompany', 'name'];

  return (
    <>
      <h6>BASE DE DATOS</h6>
      {action ? (
        <FormDatabases />
      ) : (
        <>
          <SearchPanel filterBy="companies" />
          <TableMain tableRows={tableRows} tableHead={tableHead} />
        </>
      )}
    </>
  );
};

export default Databases;
