import * as Yup from 'yup';
import { msg, rgx } from '../../../config/validationFormSettings';

export const localInitialValues = {
  identificationDocument: '',
  firstName: '',
  lastName: '',
  email: '',
};

export const formSchema = Yup.object().shape({
  identificationDocument: Yup.number()
    .min(1000000, msg.NUMBER_MIN_ERROR)
    .max(9999999999, msg.NUMBER_MAX_ERROR)
    .required(msg.REQUIRED_ERROR)
    .typeError(msg.NUMBER_TYPE_ERROR),
  firstName: Yup.string()
    .min(3, msg.STRING_MIN_ERROR)
    .max(30, msg.STRING_MAX_ERROR)
    .matches(rgx.STRING_REGEX, msg.STRING_TYPE_ERROR)
    .required(msg.REQUIRED_ERROR),
  lastName: Yup.string()
    .min(3, msg.STRING_MIN_ERROR)
    .max(30, msg.STRING_MAX_ERROR)
    .matches(rgx.STRING_REGEX, msg.STRING_TYPE_ERROR)
    .required(msg.REQUIRED_ERROR),
  email: Yup.string().email(msg.EMAIL_ERROR).required(msg.REQUIRED_ERROR),
});
