import app from 'firebase/app';
import environment from "./default";
import 'firebase/auth';

const firebaseConfig = environment.firebase;

// Initialize Firebase
// app.analytics();
const fb =  app.initializeApp(firebaseConfig);

export default fb;
