import * as Yup from 'yup';
import { msg, rgx } from '../../../config/validationFormSettings';

export const localInitialValues = {
  nit: '',
  name: '',
};

export const formSchema = Yup.object().shape({
  nit: Yup.number()
    .min(1000000000, msg.NUMBER_MIN_ERROR)
    .max(9999999999, msg.NUMBER_MAX_ERROR)
    .required(msg.REQUIRED_ERROR)
    .typeError(msg.NUMBER_TYPE_ERROR),
  name: Yup.string().matches(rgx.ALPHANUMERIC_REGEX, msg.STRING_TYPE_ERROR).required(msg.REQUIRED_ERROR),
});
