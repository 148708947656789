import React from 'react';
import PropTypes from 'prop-types';
import Container from '../container/Container';
import { NavBar, ModalNavBar } from '../../commons';

const Layout = ({ children }) => {
  return (
    <>
      <NavBar />
      <ModalNavBar />
      <Container>{children}</Container>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Layout;
