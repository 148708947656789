import * as Yup from 'yup';
import { msg } from '../../../config/validationFormSettings';

export const localInitialValues = {
  email: '',
  password: '',
};

export const formSchema = Yup.object().shape({
  email: Yup.string().email(msg.EMAIL_ERROR).required(msg.REQUIRED_ERROR),
  password: Yup.string().required(msg.REQUIRED_ERROR),
});
