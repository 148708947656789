import React from 'react';
import PropTypes from 'prop-types';

const AvatarMenu = ({ avatar, rol, username }) => {
  return (
    <div className="frame__header">
      <div className="tile level">
        <div className="tile__icon">
          <figure className="avatar">
            <img src={avatar} alt={username} />
          </figure>
        </div>
        <div className="tile__container">
          <p className="tile__title">{username}</p>
          <p className="tile__subtitle">{rol}</p>
        </div>
      </div>
    </div>
  );
};

AvatarMenu.propTypes = {
  avatar   : PropTypes.string.isRequired,
  rol      : PropTypes.string.isRequired,
  username : PropTypes.string.isRequired,
};

export default AvatarMenu;
