import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useQuery } from '../../../hooks';
import reportSchema from '../../../config/pages/reportSchemas';
import { getDataService } from '../../../services/http';
import { alertMessage, jsonToExcel } from '../../../tools';
import './searchButtonReport.css';

export const Loading = () => (
  <div className="loading-spinner-rolling">
    <div className="loading">
      <div />
    </div>
  </div>
);

const SearchButtonReport = () => {
  const filterQuery = useQuery('filter');
  const { module } = useParams();
  const [state, setState] = useState(false);

  const handleClick = async () => {
    try {
      setState(true);
      if (!filterQuery) throw Error('No ha seleccionado una opción');

      const { data, name } = await getDataService(`${module}/report/${filterQuery}`);

      if (!name) throw Error('No hay datos en la opción seleccionada');

      jsonToExcel(data, reportSchema[module], name);
    } catch (error) {
      alertMessage('error', error.message);
    }
    setState(false);
  };

  return (
    <button className="btn-transparent btn-xsmall m-0 p-0" onClick={handleClick} type="button">
      {(state && <Loading />) || <i className="search-icon fas fa-file-download" />}
    </button>
  );
};

export default SearchButtonReport;
