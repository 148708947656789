import { API_URL, API_VERSION } from '../../config/apiConfig';

const httpService = async (method, path, sendData = undefined) => {
  const apiURL = `${API_URL}${API_VERSION}${path}`;
  const { tenantId, token } = JSON.parse(window.sessionStorage.getItem('currentUser'));
  const response = await fetch(apiURL, {
    body: JSON.stringify(sendData),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}::${tenantId}`,
    },
    method: method.toUpperCase(),
  });
  return response.json();
};

export default httpService;
