import React from 'react';
import PropTypes from 'prop-types';

const HeroInfo = ({icon, subtitle, title}) => {
  return (
    <div className="hero">
      <div className="hero-body">
        <div className="content">
          <h2 className="title u-center">{title}</h2>
          <h5 className="subtitle text-gray-600 u-center">{subtitle}</h5>
          <h4 className="subtitle text-gray-600 u-center">
            <i className={`far fa-${icon}`} />
          </h4>
        </div>
      </div>
    </div>
  );
};

HeroInfo.propTypes = {
  icon     : PropTypes.string.isRequired,
  subtitle : PropTypes.string.isRequired,
  title    : PropTypes.string.isRequired,
};

export default HeroInfo;
