import React, { useEffect, useState } from 'react';

import { getDataService, postFormData } from '../../services/http';
import { PreviewContainer } from '../../components/commons';
import { FormButtons, Row, RowField } from '../../components/containers';
import { alertMessage, barcodeReader } from '../../tools';

const Upload = () => {
  const [databases, setDatabases] = useState([]);
  const [files, setFiles] = useState([]);
  const [selectDatabase, setSelectDatabase] = useState('');

  useEffect(() => {
    const fetchDatabases = async () => {
      const { results } = await getDataService('databases');
      setDatabases(results);
    };
    fetchDatabases();
  }, [setDatabases]);

  const handleOnSubmit = e => {
    e.preventDefault();
    if (!selectDatabase) {
      return alertMessage('error', 'No ha seleccionado una base de datos.');
    }

    const formData = new FormData();
    files.map(file => formData.append('files', file.image));
    formData.append('info', JSON.stringify(files.map(({ barcode, type }) => ({ barcode, type }))));
    formData.append('database', selectDatabase);
    return postFormData('upload', formData, true);
  };

  const fileOnChange = async e => {
    const barcode = await barcodeReader(Array.from(e.target.files));
    setFiles(barcode);
  };

  const selectOnChange = e => {
    setSelectDatabase(e.target.value);
  };

  return (
    <>
      <h6>CARGAR GUÍAS</h6>
      <div className="card overflow-visible glass p-2">
        <form autoComplete="off" onSubmit={handleOnSubmit}>
          <Row>
            <>
              <RowField col={6}>
                <div className="input-control">
                  <label htmlFor="database">Base de datos</label>
                  <select
                    className="select"
                    id="database"
                    onChange={selectOnChange}
                    value={selectDatabase}
                  >
                    <option value="">Base de datos</option>
                    {databases.length &&
                    databases.map(option => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </RowField>
              <RowField col={6}>
                <div className="input-control">
                  <label htmlFor="dbGuides">Seleccionar guías</label>
                  <input
                    accept="image/*"
                    className="input-xsmall"
                    multiple
                    onChange={fileOnChange}
                    type="file"
                  />
                </div>
              </RowField>
              <RowField col={12}>
                <PreviewContainer files={files} setFiles={setFiles} />
              </RowField>
              <RowField col={12}>
                <FormButtons />
              </RowField>
            </>
          </Row>
        </form>
      </div>
    </>
  );
};

export default Upload;
