import httpService from './httpService';
import { alertMessage } from '../../tools/alertMessage/alertMessage';

const putDataService = async (data, module, id) => {
  const path = `/${module}/${id}`;

  try {
    const response = await httpService('put', path, data);
    if (response.error) return alertMessage('error');
    return alertMessage('success');
  } catch (error) {
    return alertMessage('error', 'Ha sucedido un error en el servidor');
  }
};

export default putDataService;
