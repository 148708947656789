import React from 'react';
import { localInitialValues, formSchema } from './formSchema';
import { FormButtons, RowField } from '../../../components/containers';
import { FormMain, InputFormik } from '../../../components/commons';

const FormCompanies = () => {
  return (
    <FormMain localInitialValues={localInitialValues} validationSchema={formSchema}>
      <>
        <RowField col={6}>
          <InputFormik label="NIT" name="nit" type="number" />
        </RowField>
        <RowField col={6}>
          <InputFormik label="Nombre de la empresa" name="name" type="text" />
        </RowField>
        <RowField col={12}>
          <FormButtons />
        </RowField>
      </>
    </FormMain>
  );
};

export default FormCompanies;
