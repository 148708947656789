import React from 'react';
import { useHistory } from 'react-router-dom';

const BackButton = () => {
  const history = useHistory();

  return (
    <button type="button" onClick={() => history.goBack()}>
      <i className="fa-wrapper fa fa-chevron-left pad-left" />
      Regresar
    </button>
  );
};

export default BackButton;
