import React from 'react';
import PropTypes from 'prop-types';
import { Link, useParams } from 'react-router-dom';
import { useHttp } from '../../../hooks';
import './TableActions.css';

const TableActions = ({ id }) => {
  const { httpDelete } = useHttp();
  const { module } = useParams();

  return (
    <div className="list-dropdown">
      <button type="button" className="btn-transparent btn-dropdown m-0">
        <i className="fas fa-ellipsis-h" />
      </button>
      <ul className="menu">
        <li className="menu-item">
          <button
            type="button"
            className="btn-transparent btn-dropdown m-0"
            onClick={() => httpDelete(id)}
          >
            ELIMINAR
          </button>
        </li>
        <li className="menu-item">
          <Link className="bg-transparent" to={`/${module}/edit/${id}`}>
            <button type="button" className="btn-transparent btn-dropdown m-0">
              MODIFICAR
            </button>
          </Link>
        </li>
      </ul>
    </div>
  );
};

TableActions.propTypes = {
  id: PropTypes.string.isRequired,
};

export default TableActions;
