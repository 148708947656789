import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import SearchButtonReport from '../SearchButtonReport/SearchButtonReport';
import SelectLocal from '../SelectLocal/SelectLocal';
import './searchPanel.css';

const SearchPanel = ({ filterBy }) => {
  const { pathname } = useLocation();

  return (
    <div className="panel card glass overflow-visible">
      <div className="panel-select">
        <SelectLocal labelKey="name" path={filterBy} />
      </div>
      <div className="panel-actions">
        <SearchButtonReport />
        <Link to={`${pathname}/add`} className="btn-transparent btn-dropdown btn-xsmall m-0">
          <i className="fas fa-filter" />
        </Link>
      </div>
    </div>
  );
};

SearchPanel.propTypes = {
  filterBy: PropTypes.string.isRequired,
};

export default SearchPanel;
