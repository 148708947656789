export default {
  assignments: {},
  databases: {},
  guides: {
    company     : 'REMITENTE',
    barcode     : 'GUÍA',
    status      : 'ESTADO',
    observation : 'GESTIÓN',
    service     : 'SERVICIO',
    addressee   : 'DESTINATARIO',
    description : 'REFERENCIA',
    zone        : 'ZONA',
    address     : 'DIRECCIÓN',
    city        : 'MUNICIPIO',
    department  : 'DEPARTAMENTO',
  },
};
