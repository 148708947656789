import React, { useEffect, useState } from 'react';
import { getDataService, postDataService } from '../../../services/http';
import { excelToJson } from '../../../tools';
import { FormButtons, Row, RowField } from '../../../components/containers';
import { TextErrorValidate } from '../../../components/commons';
import { excelSchema, formSchema } from './formSchema';
import { msg } from '../../../config/validationFormSettings';

const FILE_SIZE = 3000000;
const SUPPORTED_FORMATS = [
  '.csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
];

const validateFile = file => {
  let errorMessage = '';
  if (!file) {
    errorMessage = msg.REQUIRED_ERROR;
  } else if (file.size > FILE_SIZE) {
    errorMessage = msg.EXCEL_FILE_SIZE_ERROR;
  } else if (!SUPPORTED_FORMATS.includes(file.type)) {
    errorMessage = msg.UNSUPPORTED_FORMATS_ERROR;
  }
  return errorMessage;
};

const validateSelect = value => {
  if (!value) {
    return msg.REQUIRED_ERROR;
  }
  return '';
};

const FormManyGuides = () => {
  const [databases, setDatabases] = useState([]);
  const [formErrors, setFormErrors] = useState({ database: '', dbGuides: '' });
  const [formValues, setFormValues] = useState({ database: '', dbGuides: [] });

  useEffect(() => {
    const fetchDatabases = async () => {
      const { results } = await getDataService('databases');
      setDatabases(results);
    };
    fetchDatabases();
  }, [setDatabases]);

  const getGuidesJson = async () => {
    const guides = await excelToJson(formValues.dbGuides, excelSchema);
    const sendGuides = guides.map(guide => {
      return { ...guide, database: formValues.database };
    });
    return sendGuides;
  };

  const validateForm = async () => {
    // Select field and file field are validated
    setFormErrors({
      database: validateSelect(formValues.database),
      dbGuides: validateFile(formValues.dbGuides),
    });

    if (validateSelect(formValues.database) || validateFile(formValues.dbGuides)) {
      return false;
    }

    const guides = await getGuidesJson();
    const validations = await Promise.all(
      guides.map(async (guide, index) => {
        formSchema.validate(guide).catch(err =>
          setFormErrors({
            ...formErrors,
            dbGuides: `Fila ${index + 1}. Error: ${err.errors[0]}.`,
          })
        );
        const result = await formSchema.isValid(guide).then(isValid => isValid);
        return result;
      })
    );
    return !validations.includes(false);
  };

  const handleOnSubmit = async e => {
    e.preventDefault();
    const isValid = await validateForm();

    if (isValid) {
      const sendData = await getGuidesJson();
      postDataService('guides', sendData);
    }
  };

  const handleOnChange = e => {
    const value = e.target.files ? e.target.files[0] : e.target.value;
    setFormValues({
      ...formValues,
      [e.target.name]: value,
    });
  };

  return (
    <div className="card overflow-visible glass p-5">
      <form autoComplete="off" onSubmit={handleOnSubmit}>
        <Row>
          <>
            <RowField col={6}>
              <div className="input-control">
                <label htmlFor="database">Base de datos</label>
                <select className="select" id="database" name="database" onChange={handleOnChange}>
                  <option value="">Base de datos</option>
                  {databases.length &&
                    databases.map(option => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                </select>
                {formErrors.database && (
                  <TextErrorValidate>{formErrors.database}</TextErrorValidate>
                )}
              </div>
            </RowField>
            <RowField col={6}>
              <div className="input-control">
                <label htmlFor="dbGuides">Elegir excel</label>
                <input
                  accept={SUPPORTED_FORMATS.join(', ')}
                  className="input-xsmall"
                  id="dbGuides"
                  name="dbGuides"
                  onChange={handleOnChange}
                  type="file"
                />
                {formErrors.dbGuides && (
                  <TextErrorValidate>{formErrors.dbGuides}</TextErrorValidate>
                )}
              </div>
            </RowField>
            <RowField col={12}>
              <FormButtons />
            </RowField>
          </>
        </Row>
      </form>
    </div>
  );
};

export default FormManyGuides;
