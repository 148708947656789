import 'firebase/firestore';
import fb from '../../config/firebaseConfig';

const db = fb.firestore();

export const sortCollection = snapshot => {
  const docs = [];
  snapshot.forEach(doc => docs.push({ id: doc.id, ...doc.data() }));
  return docs[0];
};

export const getCollectionById = (collection, id) =>
  db
    .collection(collection)
    .where('userId', '==', id)
    .get()
    .then(sortCollection)
    .catch(err => console.log(err));

export const setCollection = (data, idDoc, nameCollection) =>
  db.collection(nameCollection).doc(idDoc).set(data);
