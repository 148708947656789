import React from 'react';
import { useReduxDispatch } from '../../../hooks';
import HeroInfo from '../HeroInfo/HeroInfo';
import CardButtonActions from '../CardButtonActions/CardButtonActions';
import './assignmentCard.css';

const AssignmentCard = () => {
  const { filteredModuleData } = useReduxDispatch();
  const statusCard = {
    delivered: 'fas fa-check-circle text-success',
    pending: 'fas fa-pause-circle text-warning',
    returned: 'fas fa-times-circle text-danger',
  };

  return (
    <div className="content-cards overflow-visible glass p-5">
      {filteredModuleData.length ? (
        filteredModuleData.map(({ barcode, id, status, zoneName }) => {
          return (
            <div className="card" key={id}>
              <div className="content u-text-center">
                <span style={{ fontSize: '3em' }}>
                  <i className={statusCard[status]} />
                </span>
                <p className="title m-0">{zoneName}</p>
                <p className="m-0">{barcode}</p>
              </div>
              <div className="card__action-bar u-center">
                <CardButtonActions id={id} />
              </div>
            </div>
          );
        })
      ) : (
        <HeroInfo
          icon="folder-open"
          subtitle="No se ha encontrado ningún dato"
          title="Contenedor vacío"
        />
      )}
    </div>
  );
};

export default AssignmentCard;
