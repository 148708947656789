import swal from 'sweetalert';
import fb from '../../config/firebaseConfig';
import { getCollectionById } from './firebaseCollection';

const loginWithEmailAndPassword = async data => {
  const loginEmail = data.email.trim().toLowerCase();
  const loginPassword = data.password.trim();

  try {
    const { user } = await fb.auth().signInWithEmailAndPassword(loginEmail, loginPassword);
    const { tenantId } = await getCollectionById('users', user.uid);
    const token = await user.getIdToken();
    return {
      email: user.email,
      emailVerified: user.emailVerified,
      displayName: user.displayName,
      photoURL: user.photoURL,
      tenantId,
      token,
      refreshToken: user.refreshToken,
      uid: user.uid,
    };
  } catch (error) {
    return swal('¡Datos inválidos!', error.message, 'error', {
      buttons: false,
    });
  }
};

export default loginWithEmailAndPassword;
