import React from 'react';
import { useParams } from 'react-router-dom';
import { SearchPanel, TableMain } from '../../components/commons';
import FormGuides from './form';
import FormManyGuides from './form/FormManyGuides';

const Guides = () => {
  const { action } = useParams();

  const tableHead = [
    {
      name: 'N° Guía',
    },
    {
      name: 'Destinatario',
    },
    {
      name: 'Ciudad',
    },
    {
      name: 'Departamento',
    },
  ];

  const tableRows = ['barcode', 'addressee', 'city', 'department'];

  return (
    <>
      <h6>GUÍAS</h6>
      {(action === 'many' && <FormManyGuides />) || (action && <FormGuides />) || (
        <>
          <SearchPanel filterBy="databases" />
          <TableMain tableRows={tableRows} tableHead={tableHead} />
        </>
      )}
    </>
  );
};

export default Guides;
