import React from 'react';
import PortalModal from '../../containers/portalModal/PortalModal';
import HorizontalMenu from '../HorizontalMenu/HorizontalMenu';
import AvatarMenu from '../AvatarMenu/AvatarMenu';
import avatar from '../../../assets/img/avatar.jpg';

const ModalNavBar = () => {
  return (
    <PortalModal>
      <div className="modal-body m-0 p-0">
        <div className="frame m-0 p-0">
          <div className="frame__body">
            <AvatarMenu avatar={avatar} rol="UNIDOS - Administrador" username="Isabel Alejandra María" />
            <HorizontalMenu />
          </div>
        </div>
      </div>
    </PortalModal>
  );
};

export default ModalNavBar;
