import React from 'react';
import PropTypes from 'prop-types';
import { localInitialValues, formSchema, localDataSelectStatus } from './formSchema';
import { FormButtons, RowField } from '../../../components/containers';
import {
  InputFormik,
  FormMain,
  SelectFormik,
  SelectFormikLocalData,
  TextAreaFormik,
} from '../../../components/commons';

const FormAssignments = ({ isDisabled }) => {
  return (
    <FormMain localInitialValues={localInitialValues} validationSchema={formSchema}>
      <>
        <RowField col={6}>
          <InputFormik disabled={isDisabled} label="Código de guía" name="barcode" type="text" />
        </RowField>
        <RowField col={6}>
          <SelectFormik label="Mensajero" name="delivery" apiData={['deliveries', 'name']} />
        </RowField>
        <RowField col={6}>
          <SelectFormik label="Zona" name="zone" apiData={['zones', 'name']} />
        </RowField>
        <RowField col={6}>
          <SelectFormikLocalData label="Estado" name="status" localData={localDataSelectStatus} />
        </RowField>
        <RowField col={12}>
          <TextAreaFormik label="Observación" name="observation" />
        </RowField>
        <RowField col={12}>
          <FormButtons />
        </RowField>
      </>
    </FormMain>
  );
};

FormAssignments.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
};

export default FormAssignments;
