import * as Yup from 'yup';
import { msg, rgx } from '../../../config/validationFormSettings';

export const localInitialValues = {
  name: '',
  company: '',
};

export const formSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, msg.STRING_MIN_ERROR)
    .max(30, msg.STRING_MAX_ERROR)
    .matches(rgx.ALPHANUMERIC_REGEX, msg.STRING_TYPE_ERROR)
    .required(msg.REQUIRED_ERROR),
  company: Yup.string().required(msg.REQUIRED_ERROR).typeError(msg.REQUIRED_ERROR),
});
