import { useParams } from 'react-router-dom';

import useReduxDispatch from '../useReduxDispatch/useReduxDispatch';
import { httpService } from '../../services/http';
import { alertConfirm, alertMessage } from '../../tools';

export default function useHttp() {
  const { module } = useParams();
  const { dispatchDelete } = useReduxDispatch();

  const httpDelete = async (id) => {
    const isConfirme = await alertConfirm();
    if (!isConfirme) return alertMessage('info');;

    const path = `/${module}/${id}`;
    const response = await httpService('delete', path);
    const typeAlert = response.error ? 'error' : 'success';
    dispatchDelete(response.id);
    return alertMessage(typeAlert);
  };

  return {
    httpDelete,
  };
};
