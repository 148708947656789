import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getModuleDataById } from '../../redux/reducers/generalReducer';
import { formActions } from '../../tools';

export default function useFormInitialValue(callback, localInitialValues) {
  const { action, id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) dispatch(getModuleDataById(id));
  }, [id, dispatch]);

  const onSubmit = !callback ? formActions[action] : callback;
  const { moduleDataById } = useSelector(state => state.general);
  const initialValues = id && moduleDataById[0] ? moduleDataById[0] : localInitialValues;

  return {
    onSubmit,
    initialValues,
  };
}
