import * as Yup from 'yup';
import { msg, rgx } from '../../../config/validationFormSettings';

export const localInitialValues = {
  barcode: '',
  database: '',
  delivery: '',
  observation: '',
  status: '',
  zone: '',
};

export const formSchema = Yup.object().shape({
  barcode: Yup.number().min(0, msg.NUMBER_MIN_ERROR).typeError(msg.NUMBER_TYPE_ERROR),
  database: Yup.string().typeError(msg.REQUIRED_ERROR),
  delivery: Yup.string().required(msg.REQUIRED_ERROR).typeError(msg.REQUIRED_ERROR),
  observation: Yup.string()
    .matches(rgx.ALPHANUMERIC_REGEX, msg.STRING_TYPE_ERROR)
    .min(3, msg.STRING_MIN_ERROR)
    .max(100, msg.STRING_MAX_ERROR),
  status: Yup.string()
    .min(7, msg.STRING_MIN_ERROR)
    .max(9, msg.STRING_MAX_ERROR)
    .matches(rgx.STRING_REGEX, msg.STRING_TYPE_ERROR)
    .required(msg.REQUIRED_ERROR),
  zone: Yup.string().required(msg.REQUIRED_ERROR).typeError(msg.REQUIRED_ERROR),
});

export const localDataSelectStatus = [
  { id: 'returned', name: 'Devuelto' },
  { id: 'delivered', name: 'Entregado' },
  { id: 'pending', name: 'Pendiente' },
];
