import React from 'react';
import { localInitialValues, formSchema } from './formSchema';
import { FormButtons, RowField } from '../../../components/containers';
import { CheckboxFormik, FormMain, SelectFormik } from '../../../components/commons';

const FormZones = () => {
  return (
    <FormMain localInitialValues={localInitialValues} validationSchema={formSchema}>
      <>
        <RowField col={6}>
          <SelectFormik label="Mensajero" name="user" apiData={['users', 'displayName']} />
        </RowField>
        <RowField col={6}>
          <CheckboxFormik label="Activo" name="status" />
        </RowField>
        <RowField col={12}>
          <FormButtons />
        </RowField>
      </>
    </FormMain>
  );
};

export default FormZones;
