import httpService from './httpService';

const getDataService = async module => {
  try {
    const response = await httpService('get', `/${module}`);

    if (response.error) return { results: [] };

    return response;
  } catch (error) {
    return { results: [] };
  }
};

export default getDataService;
