import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

const PortalModalNavBar = ({ children }) => {
  return (
    <div className="modal-content" role="document">
      <div className="modal-header">
        <a href="#close-menu" className="u-pull-right" aria-label="Close">
          <span className="icon">
            <i className="fas fa-times" />
          </span>
        </a>
        <div className="modal-title">Menú</div>
      </div>
      {children}
    </div>
  );
};

PortalModalNavBar.propTypes = {
  children: PropTypes.element.isRequired,
};

const PortalModal = ({ children }) => {
  return ReactDOM.createPortal(<PortalModalNavBar>{children}</PortalModalNavBar>, document.getElementById('menu'));
};

export default PortalModal;
