import React from 'react';
import PropTypes from 'prop-types';
import './CardPreviewGuide.css';

const CardPreviewGuide = ({ error, handlePreviewImage, icon, subtitle, title }) => {
  const className = error ? 'barcode-card error' : 'barcode-card';

  return (
    <div
      className={className}
      onClick={handlePreviewImage}
      onKeyDown={handlePreviewImage}
      role="button"
      tabIndex={0}
    >
      <div className="barcode-card___icon">{icon}</div>
      <div className="barcode-card__content">
        <h3 className="barcode-card__title">{title}</h3>
        <p className="barcode-card__subtitle">{subtitle}</p>
      </div>
    </div>
  );
};

CardPreviewGuide.propTypes = {
  error              : PropTypes.bool,
  handlePreviewImage : PropTypes.func.isRequired,
  icon               : PropTypes.string.isRequired,
  subtitle           : PropTypes.string.isRequired,
  title              : PropTypes.string.isRequired,
};

CardPreviewGuide.defaultProps = {
  error: false,
};

export default CardPreviewGuide;
