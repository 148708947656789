import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';

import { getDataService } from '../../../services/http';

const SelectLocal = ({ labelKey, path }) => {
  const history = useHistory();
  const [optionData, setOptionData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    const asyncGetData = async () => {
      const { results } = await getDataService(path);
      setOptionData(
        results.map(result => ({
          value: result.id,
          label: result[labelKey],
        }))
      );
    };
    asyncGetData();
  }, [labelKey, path]);

  const handleChange = option => {
    setSelectedOption(option);
    history.push(`?filter=${option.value}&page=1`);
  };

  return <Select onChange={handleChange} options={optionData} value={selectedOption} />;
};

SelectLocal.propTypes = {
  labelKey : PropTypes.string.isRequired,
  path     : PropTypes.string.isRequired,
};

export default SelectLocal;
