import React from 'react';
import { BackButton, SubmitButton } from '../../commons';

const FormButtons = () => {
  return (
    <div className="u-flex u-justify-space-between">
      <BackButton />
      <SubmitButton />
    </div>
  );
};

export default FormButtons;
