import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext/AuthContext';

const PrivateRoute = ({ component, ...props }) => {
  const { currentUser } = useContext(AuthContext);
  const [isLogged, setIsLogged] = useState(true);

  useEffect(() => {
    setIsLogged(Boolean(currentUser));
  }, [currentUser, setIsLogged]);

  if (isLogged) return <Route {...props} component={component} />;
  return <Redirect to="/login" />;
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default PrivateRoute;
