import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  cleanState,
  deleteModuleData,
  filterModuleData,
  moduleInitData,
  paginate,
  sortModuleData,
} from '../../redux/reducers/generalReducer';

export default function useReduxDispatch() {
  const dispatch = useDispatch();
  const { moduleData, filteredModuleData, paginateInfo } = useSelector(state => state.general);

  // dispatches
  const dispatchCleanState = useCallback(() => {
    dispatch(cleanState());
  }, [dispatch]);
  const dispatchDelete = id => dispatch(deleteModuleData(id));
  const dispatchFilter = (key, value) => dispatch(filterModuleData(key, value));
  const dispatchInit = useCallback(
    currentModule => {
      dispatch(moduleInitData(currentModule));
    },
    [dispatch]
  );
  const dispatchPaginate = path => dispatch(paginate(path));
  const dispatchSort = (key, value) => dispatch(sortModuleData(key, value));

  return {
    dispatchCleanState,
    dispatchDelete,
    dispatchFilter,
    dispatchInit,
    dispatchPaginate,
    dispatchSort,
    filteredModuleData,
    moduleData,
    paginateInfo,
  };
}
