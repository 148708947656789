import React from 'react';
import { useParams } from 'react-router-dom';
import { TableMain } from '../../components/commons';
import FormUsers from './form';

const Users = () => {
  const { action } = useParams();

  const tableHead = [
    {
      name: 'Documento',
    },
    {
      name: 'Nombre',
    },
    {
      name: 'Correo',
    },
  ];

  const tableRows = ['identificationDocument', 'displayName', 'email'];

  return (
    <>
      <h6>USUARIOS</h6>
      {action ? <FormUsers /> : <TableMain tableRows={tableRows} tableHead={tableHead} />}
    </>
  );
};

export default Users;
