import { useLocation } from 'react-router-dom';
import { regexRoutes } from '../../config/validationFormSettings';

export default function useQuery(name) {
  const query = new URLSearchParams(useLocation().search);
  const queryValue = query.get(name);
  const regex = new RegExp(`${regexRoutes.moduleId}|^[0-9]+$`);
  const isValid = regex.test(queryValue);
  const resultQuery = isValid ? queryValue : '';
  return resultQuery;
}
