import React from 'react';
import { localInitialValues, formSchema } from './formSchema';
import { FormButtons, RowField } from '../../../components/containers';
import { FormMain, InputFormik, SelectFormik } from '../../../components/commons';

const FormDatabases = () => {
  return (
    <FormMain localInitialValues={localInitialValues} validationSchema={formSchema}>
      <>
        <RowField col={6}>
          <InputFormik label="Nombre" name="name" type="text" />
        </RowField>
        <RowField col={6}>
          <SelectFormik label="Empresa" name="company" apiData={['companies', 'name']} />
        </RowField>
        <RowField col={12}>
          <FormButtons />
        </RowField>
      </>
    </FormMain>
  );
};

export default FormDatabases;
