import httpService from './httpService';
import { alertMessage } from '../../tools/alertMessage/alertMessage';

const postDataService = async (module, data) => {
  const path = Array.isArray(data) ? `/${module}/many` : `/${module}`;

  try {
    const response = await httpService('post', path, data);
    if (response.error) return alertMessage('error');
    return alertMessage('success');
  } catch (error) {
    return alertMessage('error', 'Ha sucedido un error en el servidor');
  }
};

export default postDataService;
