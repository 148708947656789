import { API_URL, API_VERSION } from '../../config/apiConfig';
import { alertMessage } from '../../tools/alertMessage/alertMessage';

const postFormData = async (module, data) => {
  const { tenantId, token } = JSON.parse(window.sessionStorage.getItem('currentUser'));
  const apiURL = `${API_URL}${API_VERSION}/${module}`;

  try {
    const response = await fetch(apiURL, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}::${tenantId}`,
      },
      body: data,
    });
    if (response.error) return alertMessage('error');
    return alertMessage('success');
  } catch (error) {
    return alertMessage('error', 'Ha sucedido un error en el servidor');
  }
};

export default postFormData;
