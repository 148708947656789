import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { useHttp } from '../../../hooks';

const CardButtonActions = ({ id }) => {
  const { httpDelete } = useHttp();
  const { pathname } = useLocation();

  return (
    <>
      <button
        className="btn-transparent outline btn-xsmall"
        onClick={() => httpDelete(id)}
        type="button"
      >
        Eliminar
      </button>
      <Link className="bg-transparent" to={`${pathname}/edit/${id}`}>
        <button type="button" className="btn-transparent outline btn-xsmall">
          Editar
        </button>
      </Link>
    </>
  );
};

CardButtonActions.propTypes = {
  id: PropTypes.string.isRequired,
};

export default CardButtonActions;
