import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useUser } from '../../../hooks';
import './NavBar.css';

const NavBar = () => {
  const { logout } = useUser();
  const history = useHistory();

  return (
    <div className="header header-animated space-bottom">
      <div className="header-brand">
        <div className="nav-item no-hover">
          <Link to="/home">
            <h6 className="title">Mail Queen</h6>
          </Link>
        </div>
        <div className="nav-item nav-btn">
          <span />
          <span />
          <span />
        </div>
      </div>
      <div className="header-nav">
        <div className="nav-right">
          <div className="nav-item text-center">
            <Link to="/user">
              <span className="icon">
                <i className="fas fa-user-cog" />
              </span>
            </Link>
          </div>
          <div className="nav-item text-center">
            <a href="#menu">
              <span className="icon">
                <i className="fas fa-chess-queen" />
              </span>
            </a>
          </div>
          <div className="nav-item text-center">
            <span
              className="icon"
              onClick={() => {
                logout();
                history.push('/');
              }}
              onKeyDown={() => {
                logout();
                history.push('/');
              }}
              role="button"
              tabIndex={0}
            >
              <i className="fas fa-sign-out-alt" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
