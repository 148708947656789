import React, { useRef, useState } from 'react';
import { alertMessage } from '../../../tools';
import { postDataService } from '../../../services/http';
import { localInitialValues, formSchema, localDataSelectStatus } from './formSchema';
import { FormButtons, RowField } from '../../../components/containers';
import {
  FormMain,
  InputFileExcel,
  SelectFormik,
  SelectFormikLocalData,
} from '../../../components/commons';

const FormAssignments = () => {
  const [formState, setFormState] = useState({});
  const inputFileRef = useRef();

  const handleOnSubmit = () => {
    const [fileErrors, fileState] = inputFileRef.current.getGuidesState();

    if (fileErrors) {
      return alertMessage('error');
    }

    const sendData = fileState.map(guide => {
      return { ...guide, ...formState };
    });

    return postDataService('assignments', sendData);
  };

  const handleInputChange = event => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <FormMain
      callback={handleOnSubmit}
      localInitialValues={localInitialValues}
      validationSchema={formSchema}
    >
      <>
        <RowField col={6}>
          <InputFileExcel label="Subir Excel" name="excel" ref={inputFileRef} />
        </RowField>
        <RowField col={6}>
          <SelectFormik
            apiData={['databases', 'name']}
            label="Base de datos"
            name="database"
            onBlur={handleInputChange}
          />
        </RowField>
        <RowField col={12}>
          <SelectFormik
            apiData={['deliveries', 'name']}
            label="Mensajero"
            name="delivery"
            onBlur={handleInputChange}
          />
        </RowField>
        <RowField col={6}>
          <SelectFormik
            apiData={['zones', 'name']}
            label="Zona"
            name="zone"
            onBlur={handleInputChange}
          />
        </RowField>
        <RowField col={6}>
          <SelectFormikLocalData
            label="Estado"
            localData={localDataSelectStatus}
            name="status"
            onBlur={handleInputChange}
          />
        </RowField>
        <RowField col={12}>
          <FormButtons />
        </RowField>
      </>
    </FormMain>
  );
};

export default FormAssignments;
