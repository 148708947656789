const environment = {
  production: {
    api: {
      API_URL     : process.env.REACT_APP_MAIL_QUEEN_API_URL,
      API_VERSION : process.env.REACT_APP_API_MAIL_QUEEN_API_VERSION,
    },
    firebase: {
      apiKey            : process.env.REACT_APP_API_KEY,
      authDomain        : process.env.REACT_APP_AUTH_DOMAIN,
      projectId         : process.env.REACT_APP_PROJECT_ID,
      storageBucket     : process.env.REACT_APP_STORAGE_BUCKET,
      messagingSenderId : process.env.REACT_APP_MESSAGING_SENDER_ID,
      appId             : process.env.REACT_APP_APP_ID,
      measurementId     : process.env.REACT_APP_MEASUREMENT_ID,
    },
  },
  development: {
    api: {
      API_URL     : 'http://localhost:8080',
      API_VERSION : '/v1',
    },
    firebase: {
      apiKey            : process.env.REACT_APP_API_KEY_DEV,
      authDomain        : 'mail-queen-dev.firebaseapp.com',
      projectId         : 'mail-queen-dev',
      storageBucket     : 'mail-queen-dev.appspot.com',
      messagingSenderId : '1077010199660',
      appId             : '1:1077010199660:web:1dc8bdd470d56803c71449',
      measurementId     : 'G-XM9CVK5P46',
    },
  },
};

export default environment[process.env.REACT_APP_ENV];
