export const msg = {
  DATE_TYPE_ERROR           : 'La fecha no cumple con los requisitos',
  EMAIL_ERROR               : 'Correo electrónico no válido',
  EXCEL_FILE_SIZE_ERROR     : 'Archivo demasiado grande (Máximo 3MB)',
  NUMBER_MAX_ERROR          : 'Número por encima el límite',
  NUMBER_MIN_ERROR          : 'Número por debajo el límite',
  NUMBER_TYPE_ERROR         : 'Este campo solo debe tener número',
  REQUIRED_ERROR            : 'Este campo es obligatorio',
  STRING_MAX_ERROR          : 'Texto por encima el límite',
  STRING_MIN_ERROR          : 'Texto por debajo el límite',
  STRING_TYPE_ERROR         : 'Este campo solo debe tener texto',
  UNSUPPORTED_FORMATS_ERROR : 'Formato de archivo no soportado',
};

export const rgx = {
  ALPHANUMERIC_REGEX : /(.*)/,
  NUMBER_REGEX       : /^[.0-9]*$/,
  OBJECT_ID_REGEX    : /^[0-9a-fA-F]{24}$/,
  PASSWORD_REGEX     : /(?=(.*[0-9]))((?=.*[A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z]))^.{8,20}$/,
  STRING_REGEX       : /^[ a-z|A-Z|ñáéíóú|ÑÁÉÍÓÚ]*$/,
};

export const regexRoutes = {
  actions     : 'add|edit',
  manyModules : 'assignments|guides|home|upload',
  manyActions : 'add|edit|many',
  moduleId    : '[0-9a-fA-F]{24}|[0-9a-zA-Z]{28}',
  modules     : 'companies|databases|databases|deliveries|users|zones',
  statePaths  : 'companies|deliveries|users|zones',
};
