import { postDataService, putDataService } from '../../services/http';

const formActions = {
  add(sendData) {
    const currentModule = window.location.pathname.split('/')[1];
    postDataService(currentModule, sendData);
  },

  edit(sendData) {
    const pathname = window.location.pathname.split('/');
    putDataService(sendData, pathname[1], pathname[3]);
  },
};

export default formActions;
